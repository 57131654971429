// src/components/UserInfo.js
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Container,
    Box,
    TextField,
    Button,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';

function UserInfo() {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const mock = urlParams.get('mock');
    const isMock = mock === 'true';

    // Initialize state with empty strings
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // Load existing userInfo from localStorage on component mount
    useEffect(() => {
        const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (storedUserInfo) {
            setUserInfo(storedUserInfo);
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo((prev) => ({ ...prev, [name]: value }));
    };

    const handleBack = () => {
        navigate(`/locations${isMock ? '?mock=true' : ''}`);
    };
    const handleNext = () => {
        const { firstName, lastName, email, phone } = userInfo;

        if (firstName && lastName && email && phone) {
            // Simple email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setErrorMessage('Please enter a valid email address.');
                setOpenSnackbar(true);
                return;
            }

            // Phone number validation: only allow numbers, spaces, '+', '(', ')', and '-'
            const phoneRegex = /^[0-9\s()+-]+$/;
            if (!phoneRegex.test(phone)) {
                setErrorMessage(
                    'Please enter a valid phone number containing only numbers, spaces, +, (, ), and -.'
                );
                setOpenSnackbar(true);
                return;
            }

            // Save the userInfo to localStorage
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            navigate(`/overview${isMock ? '?mock=true' : ''}`);
        } else {
            setErrorMessage('All fields are required.');
            setOpenSnackbar(true);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box mt={5}>
                <Typography variant="h4" gutterBottom>
                    User Information
                </Typography>
                <Box component="form" noValidate autoComplete="off">
                    <TextField
                        label="First Name"
                        name="firstName"
                        value={userInfo.firstName}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Last Name"
                        name="lastName"
                        value={userInfo.lastName}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={userInfo.email}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                        type="email"
                    />
                    <TextField
                        label="Phone Number"
                        name="phone"
                        value={userInfo.phone}
                        onChange={handleChange}
                        required
                        fullWidth
                        margin="normal"
                        placeholder="Enter your phone number"
                    />
                    <Box mt={3} textAlign="right">
                        <Button
                            variant="outlined"
                            onClick={handleBack}
                            sx={{ mr: 2 }}
                        >
                            Back
                        </Button>
                        <Button variant="contained" onClick={handleNext}>
                            Next
                        </Button>
                    </Box>
                </Box>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                >
                    <Alert
                        onClose={() => setOpenSnackbar(false)}
                        severity="warning"
                        sx={{ width: '100%' }}
                    >
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    );
}

export default UserInfo;
