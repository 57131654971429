// src/components/UserSelection.js
import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import { styled, useTheme } from "@mui/system";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";

const existingUserRedirectUrl =
  process.env.REACT_APP_EXISTING_USER_REDIRECT_URL;

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: "16px",
  overflow: "hidden",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    boxShadow: `0 4px 20px ${theme.palette.primary.light}`,
    transform: "scale(1.05)",
  },
}));

const GradientBackground = styled("div")(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  height: "140px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

function Start() {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  // Get the original query string
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token"); // Get the 'token' parameter
  console.log("token:", token);

  const handleRedirect = (userType) => {
    const redirectTo =
      userType === "existing"
        ? `${existingUserRedirectUrl}`
        : navigate("/oauth");

    // Perform the redirect
    window.location.href = redirectTo;
  };

  return (
    <Box sx={{ p: { xs: 2, md: 3 } }}>
      <Box mt={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Welcome to Orders.co WIX Setup
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Please select an option to proceed
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center" mt={3}>
        {/* <Grid item xs={12} sm={6}>
                    <StyledCard onClick={() => handleRedirect('existing')}>
                        <GradientBackground>
                            <AccountCircleIcon
                                style={{
                                    fontSize: 60,
                                    color: "white",
                                    marginBottom: theme.spacing(2),
                                }}
                            />
                        </GradientBackground>
                        <CardContent
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >

                            <Typography variant="h5" component="div" gutterBottom>
                                Existing User
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                If you already have an account with Orders.co
                            </Typography>
                        </CardContent>

                    </StyledCard>
                </Grid> */}
        <Grid item xs={12} sm={6}>
          <StyledCard onClick={() => handleRedirect("new")}>
            <GradientBackground>
              <PersonAddIcon
                style={{
                  fontSize: 60,
                  color: "white",
                  marginBottom: theme.spacing(2),
                }}
              />
            </GradientBackground>
            <CardContent
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" component="div" gutterBottom>
                New Registration
              </Typography>
              <Typography variant="body2" color="textSecondary">
                If you are new to Orders.co
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Start;
