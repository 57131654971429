// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00bcd4', // Cyan-like color for a modern feel
        },
        secondary: {
            main: '#673ab7', // Deep purple to complement the primary color
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
});

export default theme;
