// src/components/Landing.js
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

// Retrieve environment variables
const wixInstallerUrl = process.env.REACT_APP_WIX_INSTALLER_URL;
const wixAppId = process.env.REACT_APP_WIX_APP_ID;
const wixRedirectUrl = process.env.REACT_APP_WIX_REDIRECT_URL;
const wixNewUserRegistrationUrl =
  process.env.REACT_APP_WIX_NEW_USER_REGISTRATION_URL;

function Landing() {
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState("");
  const [showFallback, setShowFallback] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      // Extract the 'token' query parameter from the URL
      const queryString = location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get("token"); // Get the 'token' parameter

      console.log("Token:", token);

      // Validate environment variables
      if (!wixAppId || !wixRedirectUrl || !wixInstallerUrl) {
        throw new Error("Missing required environment variables.");
      }

      // Optional: Validate the redirect URL to prevent open redirects
      const allowedDomains = [
        "https://www.wix.com",
        "https://partners.orders.co",
        "https://stage-partners.orders.co",
      ];
      const url = new URL(wixInstallerUrl);
      if (!allowedDomains.includes(url.origin)) {
        throw new Error("Redirect URL is not allowed.");
      }

      // Construct the redirect URL for existing users
      // const constructedRedirectUrl = `${wixInstallerUrl}?appId=${wixAppId}&redirectUrl=${wixRedirectUrl}&token=${token}`;
      const constructedRedirectUrl = `${wixInstallerUrl}?appId=${wixAppId}&redirectUrl=${wixNewUserRegistrationUrl}&token=${token}`;

      console.log("Constructed Redirect URL:", constructedRedirectUrl);

      // Set the redirect URL to state
      setRedirectUrl(constructedRedirectUrl);

      // Initiate the redirect
      window.location.href = constructedRedirectUrl;

      // Set a timeout to show the fallback link after 5 seconds
      const timeoutId = setTimeout(() => {
        setShowFallback(true);
      }, 5000); // 5000ms = 5 seconds

      // Cleanup the timeout on component unmount
      return () => clearTimeout(timeoutId);
    } catch (err) {
      console.error("Error during redirection:", err);
      setError(err.message);
      // Optionally, you can display a user-friendly message or take other actions here
    }
  }, [location.search]);

  // Render error message if any
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // Stack children vertically
          justifyContent: "center",
          alignItems: "center",

          textAlign: "center",
          padding: 2,
        }}
      >
        <p style={{ color: "red", marginBottom: "20px" }}>
          An error occurred: {error}
        </p>
        <p>Please try again later or contact support.</p>
      </Box>
    );
  }

  // Return a loading indicator with a spinner and fallback link
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 2, // MUI spacing scale (8px * 2 = 16px)
        gap: 0, // MUI spacing scale for consistent gaps between elements
      }}
    >
      <Box marginTop={16}>
        <CircularProgress aria-label="Loading" />
        <p>Redirecting...</p>
      </Box>
      {showFallback && redirectUrl && (
        <Box>
          <p>
            <Link href={redirectUrl}>Click here if you are not redirected</Link>
          </p>
        </Box>
      )}
    </Box>
  );
}

export default Landing;
