// src/mock/mockOAuthResponse.js
export const mockOAuthResponse = {
    success: true,
    message: "oAuth2 successfully",
    body: {
        locations: [
            {
                id: "3c707097-0d3c-4fd6-b5e5-b8aeb4d8ddd2",
                name: "4th Avenue",
                default: false,
                status: "ACTIVE",
                locationType: "UNKNOWN",
                timeZone: "America/New_York",
                address: {
                    country: "US",
                    subdivision: "NY",
                    city: "Brooklyn",
                    streetAddress: {
                        number: "",
                        name: "4th Avenue",
                        apt: ""
                    },
                    formattedAddress: "4th Avenue, Brooklyn, NY, USA",
                    geocode: {
                        latitude: 40.6490336,
                        longitude: -74.0097993
                    }
                },
                revision: "1",
                archived: false
            },
            {
                id: "8341511b-4e89-4a6d-bbac-bac72f421c41",
                name: "West 13th Street",
                default: true,
                status: "ACTIVE",
                locationType: "UNKNOWN",
                fax: "1800-555-XXX",
                timeZone: "America/New_York",
                email: "owner-email@domain.com",
                phone: "1800-555-XXX",
                address: {
                    country: "US",
                    subdivision: "NY",
                    city: "New York",
                    postalCode: "10011",
                    streetAddress: {
                        number: "47",
                        name: "West 13th Street",
                        apt: ""
                    },
                    formattedAddress: "47 W 13th St, New York, NY 10011, USA",
                    hint: "Near the school",
                    geocode: {
                        latitude: 40.736545,
                        longitude: -73.9961841
                    }
                },
                businessSchedule: {
                    periods: [],
                    specialHourPeriod: []
                },
                revision: "2",
                archived: false
            }
        ],
        auth: {
            accessToken: "token",
            refreshToken: "refreshToken",
            date: "2024-04-11T08:45:45.493Z"
        }
    }
};
