// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Container, Box } from '@mui/material';
import Logo from '../assets/logo.png'; // Assuming you have a logo image

function Header() {
    return (
        <AppBar position="static">
            <Container maxWidth="lg">
                <Box sx={{ p: { xs: 2, md: 3 } }}>
                    <Toolbar disableGutters>
                        <Box
                            component="img"
                            src={Logo}
                            alt="Orders.co Logo"
                            sx={{ height: 40, marginRight: 2 }}
                        />

                        {/* Add navigation items here if needed */}
                    </Toolbar>

                </Box>
            </Container>
        </AppBar>
    );
}

export default Header;
