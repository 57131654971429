// src/components/Layout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Box, Container } from '@mui/material';

function Layout({ children }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Header />
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Container maxWidth="lg">
                    {children}
                </Container>
            </Box>
            <Footer />
        </Box>
    );
}

export default Layout;
